/* eslint-disable consistent-return */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ phone, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/signin`,
        { phone, password },
        config,
      );

      localStorage.setItem('token', data.token);
      toast.success('Login successful');
      return data;
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Check your internet connection and try again');
      }
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({
    name, email, phone, password, confirmPass,
  }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (password !== confirmPass) {
        toast.error('Passwords do not match');
        return rejectWithValue('Passwords do not match');
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/signup`,
        {
          name, phone, email, password,
        },
        config,
      );

      localStorage.setItem('token', data.token);
      localStorage.setItem('resend', data.token);
      localStorage.setItem('phone', data.phone);
      toast.success('You are in! You will receive an OTP shortly');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      if (error.message) {
        toast.error(error.response.data.error);
        return rejectWithValue(error.response);
      }
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const verifyOTP = createAsyncThunk(
  'auth/verifyOTP',
  async (otp, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('Invalid OTP. Try again or Register');
        return rejectWithValue('No token, authorization denied');
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (otp.length < 6) {
        toast.error('OTP cannot be empty. Enter your 6 digits please');
        return rejectWithValue('OTP cannot be empty. Enter your 6 digits please');
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/verify-otp`,
        { otp },
        config,
      );

      toast.success('Phone number verified successfully');
      localStorage.removeItem('token');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error('Invalid OTP. Try again');
      }
      toast.error('Invalid OTP. Try again');
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const resendOTP = createAsyncThunk(
  'auth/resendOTP',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('resend');
      if (!token) {
        toast.error('Invalid OTP. Try again or Register');
        return rejectWithValue('No token, authorization denied');
      }
      console.log(token);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/resend-otp`,
        { },
        config,
      );
      toast.success('OTP sent successfully');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem('token');
      toast.success('Hope to see you again soon!');
      return 'User logged out successfully';
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
