import React, { useEffect } from 'react';
import Confirm from '../PalmSchool/pages/Confirm';

const VerifyReset = () => {
  const phoneNo = JSON.parse(localStorage.getItem('phoneNo'));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Confirm phoneNo={phoneNo} />;
};

export default VerifyReset;
