import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import App from './App';
import Loader from './Home/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (window.innerWidth > 7609) {
  root.render(
    <Loader />,
  );

  setTimeout(() => {
    root.render(
      <Provider store={store}>
        <App />
      </Provider>,
    );
  }, 3000);
} else {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
