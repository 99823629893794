/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdKeyboardBackspace } from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import { RiLockPasswordLine } from 'react-icons/ri';
import 'aos/dist/aos.css';
import { motion } from 'framer-motion';
import styles from './css/Login.module.scss';
import logo from '../images/logo/logo_img.svg';
import { signIn } from '../../redux/reducer/authActions';
import Loader from '../components/Loader';

const Login = () => {
  const [fetching, setFetching] = useState(false);
  const {
    isAuthenticated,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    phone: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFetching(true);
    dispatch(signIn(formData));
    if (!isAuthenticated) setFetching(false);
    setTimeout(() => {
      if (isAuthenticated) {
        navigate('/');
      }
    }, 5000);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate, isAuthenticated]);

  return (
    <motion.section
      initial={{ opacity: 0, x: '-100%' }}
      whileInView={{ opacity: 1, x: 0 }}
      className={styles.container}
    >
      <MdKeyboardBackspace className={styles.angle} onClick={handleBackClick} />
      <img src={logo} alt="PalmShops.com logo" className={styles.logo} />
      <article className={styles.title}>
        <h1 className={styles.subtitle}>Login</h1>
        <p className={styles.desc}>Welcome back</p>
      </article>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          type="tel"
          id="phone"
          name="phone"
          className={styles.input}
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
        <span className={styles.icon}>
          <FiPhone className={styles.svg} />
        </span>
        <input
          type="password"
          id="password"
          name="password"
          className={styles.input}
          placeholder="Password"
          value={formData.password}
          minLength={6}
          onChange={(event) => handleInputChange(event)}
          required
        />
        <span className={styles.icon}>
          <RiLockPasswordLine className={styles.svg} />
        </span>
        <button type="submit" className={styles.btn} disabled={fetching}>
          Login
        </button>
        <p className={styles.link}>
          New here?
          <Link to="/register"> Create Account</Link>
        </p>
        <p className={styles.linker}>
          Forgot password?
          <Link to="/find-my-account"> Click here</Link>
        </p>
      </form>
      {fetching && <Loader loading={fetching} />}
    </motion.section>
  );
};

export default Login;
